import './App.css';
import Swap from '@dexhunterio/swaps'
import '@dexhunterio/swaps/lib/assets/style.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Swap
      orderTypes={["SWAP","LIMIT"]}
      defaultToken="2d92af60ee429bce238d3fd9f2531b45457301d74dad1bcf3f9d1dca564e4d"
      colors={{"background":"#0E0F12","containers":"#191B23","subText":"#8500FF","mainText":"#D9E3F0","buttonText":"#D9E3F0","accent":"#8500FF"}}
      theme="dark"
      width="450"
      partnerCode="platypuscyberpunks61646472317178306872636a6d6a366d38656d396c6d363834366870396a6763616c30676a6338616d6871736c67736d613930306365346e6d393465377a666b6d61656d343673356c7a6e6a6835677a63326439616d7930673467653879756771787775323861da39a3ee5e6b4b0d3255bfef95601890afd80709"
      partnerName="PlatypusCyberpunks"
      displayType="FULL"
    />
      </header>
    </div>
  );
}

export default App;
